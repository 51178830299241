.values {
  color: white;
  padding: 10px;
  margin: 1rem auto;
  h3 {
    font-size: calc(1vw + 0.5rem);
    font-weight: 900;
    margin-bottom: 0.5rem;
  }
  i {
    font-size: 4rem;
    margin-bottom: 1rem;
  }
}
