.footer {
  padding: 20px 10px;
  width: 100%;
  background: var(--main-color);
  overflow: hidden;

  .footer__subtitle {
    margin-bottom: 1.5rem;
    margin-top: 4rem;
  }

  .footer__container {
    display: flex;
    width: 80%;
    margin: auto;
    color: white;
    justify-content: space-between;
    align-items: flex-start !important;

    @media (max-width: 900px) {
      & {
        flex-direction: column;
      }
    }

    .footer__social__names {
      display: flex;
      margin-bottom: 1rem;
    }

    .footer__logo {
      width: 30%;
      margin: auto;
      @media (max-width: 900px) {
        & {
          margin-top: 2rem;
          text-align: center;
          width: 100%;
        }
      }
      h2 {
        font-size: 30px;
        font-weight: 900;
      }
      ul {
        padding: 20px 35px;
        color: rgb(165, 165, 165);
        li {
          margin-bottom: 1rem;
        }
      }
    }

    .footer__social {
      width: 20%;
      margin: auto;
      @media (max-width: 900px) {
        & {
          text-align: center !important;
          width: 100%;
        }
      }

      .footer__social__names {
        color: rgb(165, 165, 165);
        @media (max-width: 900px) {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        i {
          font-size: 25px;
          margin-right: 10px;
        }
      }
    }

    .footer_extra {
      text-align: center;
      width: 30%;
      margin: 0rem auto;
      @media (max-width: 900px) {
        & {
          width: 100%;
        }
      }
      a {
        background: white;
        border-radius: 1rem;
        padding: 10px 20px;
      }
    }
  }
}
