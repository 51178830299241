.sliderBig {
  width: 100%;
  height: 100vh;
  position: relative;
  .sliderImage {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    filter: brightness(50%);
  }
  .sliderBigTitle {
    position: absolute;
    width: 50%;
    height: 300px;
    left: 5%;
    bottom: 0;
    top: 0;
    margin: auto;
    display: flex;
    align-items: center;
    h1 {
      font-size: calc(4vw + 1rem);
      text-transform: uppercase;
      font-weight: 900;
      color: white;
    }
  }
  .sliderTitleContainer {
    width: 80%;
    height: 80px;
    position: absolute;
    left: 5%;
    bottom: 0;
    margin: auto;
    z-index: 800;
    color: var(--text-white);
    display: flex;
    align-items: center;
    text-transform: uppercase;
    .sliderTitle {
      margin-left: 0.7rem;
      align-items: left;
      font-weight: 900;
      margin-right: 1rem;
    }
    .sliderIcon {
      font-size: 20px;
      font-size: 900;
      color: var(--second-color);
    }
  }
}
