.business {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;

  overflow: hidden;

  @media (max-width: 1000px) {
    & {
      flex-direction: column;
      height: 150vh;
    }
  }

  @media (max-width: 400px) {
    & {
      height: auto !important;
    }
  }

  .business__each {
    width: 100%;
    padding: 30px;
    .business__title {
      font-size: calc(1vw + 2rem);
      text-transform: uppercase;
      font-weight: 900;
      margin-bottom: 2rem;
      color: var(--text-semidark);
      @media (max-width: 800px) {
        & {
          text-align: center;
        }
      }
    }
    .business__container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      @media (max-width: 400px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
  .business__information {
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    transition: all 500ms;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 800px) {
      & {
        background-color: red !important;
      }
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(24, 24, 24, 0.438);
    }

    .business__information__content {
      z-index: 200;
      text-align: center;
      color: white;
      h1 {
        text-transform: uppercase;
        font-size: calc(2vw + 1rem);
      }
      p {
        margin-bottom: 2rem;
      }
      a {
        text-align: center;
        background: white;
        color: black;
        padding: 10px 20px;
        border-radius: 10px;
        transition: all 500ms;
        &:hover {
          background: rgb(222, 222, 222);
        }
      }
    }
  }
}
