header {
  width: 100%;
  height: 120px;
  color: white;
  position: absolute;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  .header__contact {
    position: absolute;
    background: linear-gradient(
      to right,
      var(--main-color),
      var(--third-color)
    );
    color: white;
    padding: 7px 15px;
    top: 0%;
    right: 9.8%;
    font-size: 13px;
    font-weight: bold;
    a {
      color: white;
    }
    @media (max-width: 900px) {
      display: none;
    }
  }

  .header__container {
    width: 80%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 900px) {
      display: none !important;
    }

    .header__logo {
      display: flex;
      align-items: center;
      flex: 1;
      a {
        color: white;
        font-weight: bold;
      }

      img {
        margin-right: 1rem;
        width: 20%;
      }
    }

    .header__options {
      flex: 0.8;

      ul {
        a {
          color: white !important;
        }
        display: flex;
        align-items: center;
        list-style: none;
        justify-content: space-between;

        li {
          margin-top: 0.7rem;
          font-weight: bold;
          transition: all 500ms;
          &:hover {
            transform: scale(1.08);
            background: var(--main-color);
            color: white;
            padding: 10px 20px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

/* responsive */

.header__mobile__all {
  display: none;
  width: 100%;
  @media (max-width: 859px) {
    display: block;
  }
}

.header__mobile {
  width: 97%;
  margin: auto;
  display: flex !important;
  align-items: center;
  justify-content: space-between;

  .header__mobile__logo {
    display: flex;
    align-items: center;
    font-size: calc(1vw + 0.5rem);
    img {
      width: 100px;
    }
  }

  .header__mobile__burguer {
    i {
      font-size: 2rem;
      margin-right: 1rem;
      cursor: pointer;
    }
  }
}

.header__mobile__options {
  width: 50%;
  height: 100vh;
  display: flex;
  background-color: white;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  color: black;
  padding: 10px;

  @media (max-width: 400px) {
    & {
      width: 70%;
    }
  }
  ul {
    li {
      font-weight: bold;
      margin: 1rem auto;
      padding: 1rem 10px;
      transition: all 500ms;
      cursor: pointer;

      &:hover {
        transform: scale(1.08);
        background: var(--main-color);
        color: white;
      }
    }
  }
  .contact__mobile {
    background: linear-gradient(
      to right,
      var(--main-color),
      var(--third-color)
    );
    color: white;
    font-size: 13px;
    font-weight: bold;
    a {
      color: white;
    }
  }
  img {
    margin: 0 auto;
    width: 70%;
  }
}

.activeLink {
  background-color: var(--main-color);
  color: white;
  padding: 5px 20px;
  text-align: center;
  border-bottom: solid 2px white;
  margin-left: 0px !important;
}
