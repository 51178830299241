@use "./base/reset";
@use "./base/scroll";

/* layout */

@use "./layout/header";
@use "./layout/footer";
@use "./layout/content";

/* variables */
@use "./utilities/variables";
@use "./base/typo";

html {
  scroll-behavior: smooth;
}

.slick-prev:before,
.slick-next:before {
  color: black;
}
