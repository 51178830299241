.banner {
  width: 100%;
  height: 60vh;
  background-repeat: no-repeat;
  background-size: 110%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  animation: move 10s infinite alternate;

  @media (max-width: 700px) {
    & {
      background-size: 180%;
    }
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.397);
  }

  h1 {
    font-size: calc(1vw + 1.7rem);
    text-transform: uppercase;
    z-index: 100;
    color: white;
    margin-top: 10rem;
  }

  i {
    color: rgb(255, 255, 255) !important;
    font-size: calc(2rem);
    z-index: 100;
    display: block;
    margin-top: 1rem;
  }
}

@keyframes move {
  from {
    background-position: left;
  }
  to {
    background-position: right;
  }
}
