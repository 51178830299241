.subheader {
  width: 40%;
  background: var(--fourth-color);
  color: white;
  height: 100vh;
  @media (max-width: 800px) {
    width: 10%;
  }
  ul {
    list-style: none;
    a {
      color: white !important;
    }
    li {
      margin: 2rem auto;
      padding-left: 1rem;
      text-transform: uppercase;
      font-weight: 300;
      position: relative;
      cursor: pointer;
      padding: 20px 10px;
      transition: all 500ms;

      p {
        @media (max-width: 800px) {
          display: none;
        }
      }

      i {
        display: none;
        @media (max-width: 800px) {
          display: block;
          margin: auto;
          text-align: center;
        }
      }

      &:hover {
        background: var(--fourth-color-hover);
      }

      /* &:hover ~ &::before {
        width: 100%;
      } */

      &::before {
        content: "";
        position: absolute;
        bottom: -5%;
        width: 20%;
        background-color: rgb(211, 211, 211);
        height: 2px;
        transition: all 500ms;
        @media (max-width: 800px) {
          display: none;
        }
      }
    }
  }
}
