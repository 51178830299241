.cardBusiness {
  position: relative;
  width: 280px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-color);
  border-left: solid 5px var(--main-color);
  padding: 10px;
  position: relative;
  transition: 500ms !important;
  cursor: pointer;
  margin: 1rem auto !important;
  box-shadow: 0 0 7px 1px var(--shadow-light);

  @media (max-width: 1300px) {
    & {
      width: 230px;
    }
  }

  &:hover {
    background: var(--main-color);
    color: white;
  }

  .cardBusinessHead {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .business__icon {
      font-size: calc(2rem + 2vw);
      margin-bottom: 2rem;
    }
  }
  h1 {
    font-size: 16px;
    text-transform: uppercase;
  }
}
/* .cardBusiness0 {
  float: left;
  @media (max-width: 800px) {
    & {
      float: none;
    }
  }
}

.cardBusiness1 {
  float: right;
  @media (max-width: 800px) {
    & {
      float: none;
    }
  }
} */
