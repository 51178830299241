.about {
  width: 100%;
  .about__container {
    width: 80%;
    margin: 6rem auto;
    display: flex;
    @media (max-width: 900px) {
      & {
        flex-direction: column;
      }
    }
    .about__information {
      width: 80%;
      position: relative;
      padding: 10px;
      @media (max-width: 900px) {
        & {
          width: 100% !important;
        }
      }
      h1 {
        font-size: calc(1vw + 2rem);
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: 0.3rem;
        color: var(--main-color);
      }
      h3 {
        margin-bottom: 1.6rem;
      }

      p {
        padding: 10px;
        font-size: 15px;
        line-height: 25px;
      }
      a {
        font-weight: bold;
        color: var(--main-color);
        display: block;
        padding: 20px 10px;
      }
    }
    .about__images {
      columns: 3 100px;
      column-gap: 0.5rem;
      width: 100% !important;

      img {
        display: block;
        max-width: 100%;
        margin-bottom: 0.5rem;
      }
    }
  }
  .about__another {
    width: 80%;
    margin: 5rem auto;
    display: flex;
    overflow: hidden;
    text-align: center;
    @media (max-width: 900px) {
      & {
        flex-direction: column;
      }
    }
    h1 {
      font-size: calc(1vw + 1.5rem);
      margin-bottom: 1rem;
      text-transform: uppercase;
    }
    p {
      font-size: 15px;
      line-height: 25px;
    }
    i {
      font-size: 20px;
      margin-bottom: 1.5rem;
    }
    .about__mision {
      width: 100%;
      padding: 30px 15px;
      cursor: pointer;
    }
    .about__vision {
      width: 100%;
      cursor: pointer;
      padding: 30px 15px;
      background: var(--main-color);
      color: white;
    }
  }
  .about__values {
    width: 100%;
    height: 100vh;
    margin: auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    background-image: url(https://images.pexels.com/photos/1469885/pexels-photo-1469885.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);

    @media (max-width: 900px) {
      & {
        height: auto;
      }
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.397);
    }

    .about__values__container {
      width: 80%;
      margin: auto;
      text-align: center;
      padding: 10px;
      position: relative;

      @media (max-width: 900px) {
        & {
          width: 90%;
        }
      }

      h1 {
        font-size: calc(1vw + 1.7rem);
        text-transform: uppercase;
        z-index: 100;
        color: white;
        margin-top: 3rem;
        font-weight: 900;
      }
      .about__values__grid {
        margin-top: 3rem;
        gap: 1rem;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        @media (max-width: 900px) {
          & {
            grid-template-columns: repeat(3, 1fr);
          }
        }
        @media (max-width: 700px) {
          & {
            grid-template-columns: repeat(2, 1fr);
          }
        }
      }
    }
  }
}
