.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10000;
  background: white;

  .loader__container {
    width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .loader__image {
      width: 30%;
      display: block;
      margin: auto;

      @media (max-width: 800px) {
        & {
          width: 70%;
        }
      }
    }

    h1 {
      color: var(--text-semidark);
      text-transform: uppercase;
      margin-top: 2rem;
      text-align: center;
      font-size: 15px;
    }
  }
}
