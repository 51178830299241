:root {
  --main-color: #002983;
  --second-color: rgb(13, 228, 228);
  --third-color: rgb(0, 116, 240);
  --fourth-color: rgb(64, 64, 64);
  --text-white: white;
  --text-semidark: rgb(59, 59, 59);
  --shadow-light: rgba(0, 0, 0, 0.119);

  /* hover */
  --main-color-hover: #001542;
  --second-color-hover: rgb(12, 211, 211);
  --fourth-color-hover: rgb(43, 43, 43);
}
