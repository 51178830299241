.contact {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  right: 3%;
  bottom: 0;
  top: 0;
  margin: auto;
  z-index: 888;
  .contact__icon {
    background: white;
    font-size: 1.5rem;
    padding: 10px;
    border-radius: 50%;
    margin-bottom: 1.4rem;
    cursor: pointer;
    box-shadow: 0 0 10px 1px var(--shadow-light);
    transition: all 500ms;
    &:hover {
      background: var(--main-color);
      color: var(--text-white);
      transform: scale(1.3);
    }
  }
}
