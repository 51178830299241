.pages__business {
  width: 80%;
  margin: auto;
  overflow: hidden;

  @media (max-width: 900px) {
    width: 100%;
    padding: 0;
  }
  @media (max-width: 700px) {
    width: 100%;
  }

  .page__container {
    display: flex;
    padding: 10px;
    @media (max-width: 900px) {
      padding: 0;
    }

    .page__information {
      width: 100%;
      padding: 20px;
      margin: auto;
      transition: all 500ms;
      h1 {
        text-transform: uppercase;
        font-weight: 900;
        margin-bottom: 1rem;
      }
      p {
        padding: 5px;
        font-size: 15px;
      }
      .page__information__image {
        display: flex;
        padding: 10px;
        margin-top: 1.5rem;
        img {
          display: block;
          margin: auto;
          height: 400px;
          width: 30%;
          object-fit: cover;
        }
      }

      .page__information__container {
        text-align: center;
      }
    }
  }
}
