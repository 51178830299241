.pagebanner {
  width: 100%;
  height: 70vh;

  .pagebanner__container {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;

    .pagebanner__information {
      padding: 0 30px;
      width: 80%;
      height: 100%;
      display: flex;
      align-items: center;
      background: var(--main-color);
      h1 {
        text-transform: uppercase;
        font-weight: 900;
        color: white;
      }
      .pagebanner__information__letter {
        width: 70%;
        margin: auto;
      }
    }

    .pagebanner__img {
      position: relative;
      width: 100%;
      height: 100%;
      animation: moven 20s infinite alternate;
      transition: all 500ms;

      .arrow__banner {
        position: absolute;
        top: 0;
        bottom: 0;
        left: -7%;
        margin: auto;
        height: 200px;

        i {
          font-size: calc(8rem + 3vw);
          color: rgb(238, 238, 238);
        }
      }

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.448);
      }
    }
  }
}

@keyframes moven {
  from {
    background-position: left;
  }
  to {
    background-position: right;
  }
}
