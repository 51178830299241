.contact__alll {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  @media (max-width: 1000px) {
    height: auto;
    flex-direction: column-reverse;
  }
  .contact__map {
    width: 90%;
    height: 100vh;
    iframe {
      height: 100%;
      width: 100%;
    }
    @media (max-width: 1000px) {
      height: 50vh;
      margin-bottom: 2rem;
    }
  }

  .contact__form__container {
    width: 100%;
    text-align: center;
    h1 {
      text-transform: uppercase;
      margin: 3rem;
    }
  }
  .contact__form {
    margin: auto;
    width: 90%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    .input__form {
      width: 100%;
      display: flex;
      flex-direction: column;
      .input__all {
        display: flex;
        align-items: center;
      }
      .input__form__input {
        background: rgb(247, 247, 247);
        width: 100%;
        margin-bottom: 1rem;
        position: relative;
        .decorer {
          width: 100%;
          position: relative;
          transition: all 500ms;
          &::before {
            position: absolute;
            content: "";
            background-color: var(--main-color);
            bottom: 0;
            left: 0;
            width: 0%;
            height: 3px;
            transition: all 500ms;
          }
        }

        input {
          height: 100%;
          background: none;
          padding: 10px 16px;
          width: 100%;
          border: none;
          position: relative;
          display: block;
        }
      }

      label {
        padding-left: 1rem;
        margin-bottom: 0.5rem;
        text-align: left;
      }
    }
  }
  button {
    padding: 10px 40px;
    background: var(--main-color);
    color: white;
    border: none;
    display: block;
    font-weight: 600;
    margin: 1rem auto;
    cursor: pointer;
    font-size: 18px;
  }
}

input:focus ~ .decorer::before {
  width: 100% !important;
}

.text__area {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  text-align: left !important;
  label {
    padding-left: 1rem;
    margin-bottom: 0.5rem;
    text-align: left;
  }
}

textarea {
  padding: 10px;
  min-width: 100%;
  max-width: 100% !important;
  height: 100px;
  max-height: 100px;
  background: rgb(247, 247, 247);
  border: none;
}
